.main-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #111314;
}

.header {
  width: 100vw;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url("assets/curtain-vertical copia.png");
  background-size: 150vw;
  background-position: top;
}

.logo {
  width: 10vw;
}

@media only screen and (max-width: 600px) {
  .header {
    width: 100vw;
    height: 15vh;

    background-image: url("assets/curtain-vertical copia.png");
    background-size: 250vw;
    background-position: top;
  }

  .logo {
    width: 40vw;
  }
}